import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { push } from 'react-router-redux';
import store, { history } from './store';
import App from './containers/app';
import {
  checkSession
} from './modules/auth';

import 'sanitize.css/sanitize.css';
import './styles/containers/index.scss';

if(!localStorage.getItem('loggedIn')) {
  store.dispatch(push('login'))
} else {
  checkSession(localStorage.getItem('token'));
}

const target = document.querySelector('#root')

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <App />
      </div>
    </ConnectedRouter>
  </Provider>,
  target
)
