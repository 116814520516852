import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ContentHeader from '../../components/ContentHeader'
import { Input } from '../../components/Forms'
import {
    getCity,
    updateCity,
    uploadImage,
    resetCity,
    onInputChange
} from '../../modules/cities'
import { ImagesPath } from '../../constants';

class EditCity extends PureComponent {
    
    constructor() {
        super()

        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onUploadImage = this.onUploadImage.bind(this)

        this.onInputChange = this.onInputChange.bind(this)
    }

    componentDidMount() {
        this.props.getCity(this.props.match.params.id)
    }
    
    componentWillUnmount() {
        this.props.resetCity()
    }

    onInputChange(e) {
        this.props.onInputChange(e)
    }

    onFormSubmit(e) {
        e.preventDefault();
        this.props.updateCity(this.props.city)
    }
    
    onUploadImage(e) {
        this.props.uploadImage(e.target.files[0])
    }

    render() {
        const { city = {}, city: { images = {} }, uploadingImage } = this.props
        return(
            <Fragment>
                <ContentHeader
                    title="Edit City"
                    description="Change the existing fields below to update selected city." />
                <div className="main-content forms">
                    {images.small && <div className="image-preview" style={{ backgroundImage: `url(${ImagesPath}/${images.small})` }}></div>}
                    <Input
                        type="file"
                        onChange={this.onUploadImage}
                        placeholder="Upload Image" />
                    {uploadingImage && <span className="uploading-image">Uploading image, please wait..</span>}
                    <form onSubmit={this.onFormSubmit}>
                        <Input
                            name="name"
                            value={city.name}
                            placeholder="City name"
                            onChange={this.onInputChange} required />
                        <button>Update city</button>
                    </form>
                </div>
            </Fragment>
        )
    }
}

EditCity.propTypes = {
    city: PropTypes.object.isRequired
}

const mapStateToProps = ({ cities }) => ({
    city: cities.city,
    uploadingImage: cities.uploadingImage
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getCity,
    updateCity,
    uploadImage,
    resetCity,
    onInputChange
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCity)