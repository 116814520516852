import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

export const Input = ({ name, value, type = "text", placeholder, onChange, showLabel = true, ...props  }) => (
    <Fragment>
        {showLabel && <label for={name}>{name}</label>}
        <input
            name={name}
            value={value}
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            autoComplete="off"
            {...props} />
    </Fragment>
)

Input.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
}

export const Textarea = ({ name, defaultValue, placeholder, onChange, ...props }) => (
    <Fragment>
        <label for={name}>{name}</label>
        <textarea
            name={name}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onChange={onChange}
            {...props}></textarea>
    </Fragment>
)

Textarea.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
}

export class Select extends PureComponent {
    
    /**
     * The main constructor for Select Class
     * @memberof Select
     */
    constructor() {
        super()

        this.handleChange = this.handleChange.bind(this)
    }

    /**
     * handleChange is called upon the change of the select value
     * then the "handleSelect" prop is called with the queryKey and the value.
     * 
     * @memberof Select
     * @param {Object} e - the event from the onChange handler
     */
    handleChange(e) {
        this.props.handleSelect({
            target: {
                name: this.props.queryKey,
                value: e.target.value
            }
        })
    }

    /**
     * Renders the Select Class
     * 
     * @memberof Select
     * @returns JSX
     */
    render() {
        const { title, items = [], selectedValue = '' } = this.props
        return(
            <Fragment>
                <label for={title}>{title}</label>
                <select 
                    onChange={this.handleChange}
                    value={typeof selectedValue === 'object' ? selectedValue._id : selectedValue} name={title}>
                    <option value="" hidden>{title}</option>
                    {items.map((item, index) => 
                        <option
                            key={`${index}`}
                            value={item._id}>
                            {item.name}
                        </option>
                    )}
                </select>
            </Fragment>
        )
    }
}

Select.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    selectedValue: PropTypes.string,
    handleSelect: PropTypes.func.isRequired,
    queryKey: PropTypes.string.isRequired
}