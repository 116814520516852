import { axios } from '../services';
import { push } from 'react-router-redux';
import store from '../store';
export const INCORRECT_PASSWORD = 'auth/INCORRECT_PASSWORD';

const initialState = {
    incorrectPassword: false
}

export default ( state = initialState, action ) => {
    switch(action.type) {
        case INCORRECT_PASSWORD:
            return {
                ...state,
                incorrectPassword: true
            }
        default:
            return state
    }
}

const incorrectPassword = () => ({
    type: INCORRECT_PASSWORD
})

function setLocalStorage(username, token) {
    localStorage.setItem('username', username);
    localStorage.setItem('token', token);
    localStorage.setItem('loggedIn', true);
}

function removeLocalStorage() {
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    localStorage.removeItem('loggedIn');
}

export const checkSession = token => {
    const dispatch = store.dispatch;
    axios.post(`/auth/session`, { token })
        .then(response => {
            setLocalStorage(response.data.username, response.data.token);
        })
        .catch(error => {
            removeLocalStorage();
            dispatch(push('/login'));
        })
}

export const doLogin = (username, password) => {
    return dispatch => {
        axios.post(`/auth/login`, { username, password })
            .then(response => {
                setLocalStorage(username, response.data);
                dispatch(push('/hairstylists'))
            })
            .catch(() => {
                removeLocalStorage();
                dispatch(incorrectPassword());
            })
    }
}

export const doLogout = () => {
    return dispatch => {
        axios.post(`/auth/logout`, { token: localStorage.getItem('token') })
            .then(() => {
                dispatch(push('/login'))
            })
            .catch(() => {
                removeLocalStorage();
                dispatch(push('/login'));
            })
    }
}