import { combineReducers } from 'redux'
import cities from './cities'
import hairstylists from './hairstylists'
import auth from './auth'

export default combineReducers({
  cities,
  hairstylists,
  auth
})
