import { axios } from '../services'
import { push } from 'react-router-redux'
export const GET_HAIRSTYLISTS           =   'hairstylists/GET_HAIRSTYLISTS'
export const GET_HAIRSTYLIST            =   'hairstylists/GET_HAIRSTYLIST'
export const DELETE_HAIRSTYLIST         =   'hairstylists/DELETE_HAIRSTYLIST'
export const RESET_HAIRSTYLIST          =   'hairstylists/RESET_HAIRSTYLIST'
export const RESET_HAIRSTYLISTS         =   'hairstylists/RESET_HAIRSTYLISTS'

export const HANDLE_INPUT_CHANGE        =   'hairstylists/HANDLE_INPUT_CHANGE'
export const UPLOAD_IMAGE               =   'hairstylists/UPLOAD_IMAGE'

export const SET_UPLOADING_IMAGE        =   'hairstylists/SET_UPLOADING_IMAGE'

const initialState = {
    hairstylists: [],
    hairstylist: {},
    loading: true,
    uploadingImage: false
}

export default ( state = initialState, action ) => {
    switch(action.type) {
        case GET_HAIRSTYLISTS:
            return {
                ...state,
                hairstylists: action.payload,
                loading: false
            }
        case GET_HAIRSTYLIST:
            return {
                ...state,
                hairstylist: action.payload,
                loading: false
            }
        case DELETE_HAIRSTYLIST:
            return {
                ...state,
                hairstylists: state.hairstylists.filter(hairstylist => hairstylist._id !== action.payload._id)
            }
        case RESET_HAIRSTYLIST:
            return {
                ...state,
                hairstylist: {},
                loading: true
            }
        case RESET_HAIRSTYLISTS:
            return {
                ...state,
                hairstylists: [],
                loading: true
            }
        case HANDLE_INPUT_CHANGE:
            return {
                ...state,
                hairstylist: {
                    ...state.hairstylist,
                    [action.payload.name]: action.payload.value
                }
            }
        case UPLOAD_IMAGE:
                return {
                    ...state,
                    hairstylist: {
                        ...state.hairstylist,
                        images: action.payload
                    },
                    uploadingImage: false
                }
        case SET_UPLOADING_IMAGE:
            return {
                ...state,
                uploadingImage: true
            }
        default:
            return state
    }
}

/**
 * Calls the GET_HAIRSTYLISTS reducer
 * 
 * @param {Array} hairstylists 
 */
const get_hairstylists = hairstylists => ({
    type: GET_HAIRSTYLISTS,
    payload: hairstylists
})

/**
 * Calls the GET_HAIRSTYLIST reducer
 * 
 * @param {Object} hairstylist 
 */
const get_hairstylist = hairstylist => ({
    type: GET_HAIRSTYLIST,
    payload: hairstylist
})

/**
 * Calls the DELETE_HAIRSTYLIST
 * 
 * @param {String} _id 
 */
const delete_hairstylist = _id => ({
    type: DELETE_HAIRSTYLIST,
    payload: { _id }
})

/**
 * Calls the RESET_HAIRSTYLIST reducer
 * 
 */
const reset_hairstylist = () => ({
    type: RESET_HAIRSTYLIST
})

/**
 * Calls the RESET_HAIRSTYLISTS reducer
 * 
 */
const reset_hairstylists = () => ({
    type: RESET_HAIRSTYLISTS
})

/**
 * Calls the HANDLE_INPUT_CHANGE reducer
 * 
 */
const handle_input_change = (name, value) => ({
    type: HANDLE_INPUT_CHANGE,
    payload: { name, value }
})

/**
 * Calls the UPLOAD_IMAGE reducer
 * 
 * @param {Object} images
 */
const upload_image = images => ({
    type: UPLOAD_IMAGE,
    payload: images
})

/**
 * Calls the SET_UPLOADING_IMAGE reducer
 * 
 */
const set_uploading_image = () => ({
    type: SET_UPLOADING_IMAGE
})

/**
 * Get the hairstylists array
 * 
 * @returns dispatch action
 */
export const getHairstylists = () => {
    return dispatch => {
        axios.get('/hairstylists')
            .then(response => dispatch(get_hairstylists(response.data)))
            .catch(error => console.log(`Todo => Handle error.`))
    }
}

/**
 * Get a single hairstylist with id
 * 
 * @param {String} id 
 * @returns dispatch action
 */
export const getHairstylist = id => {
    return dispatch => {
        axios.get(`/hairstylist/${id}`)
            .then(response => dispatch(get_hairstylist(response.data)))
            .catch(error => console.log(`Todo => Handle error.`))
    }
}

/**
 * Update hairstylist
 * 
 * @param {Object} hairstylist
 * @returns dispatch action 
 */
export const updateHairstylist = hairstylist => {
    return dispatch => {
        axios.post(`/update/hairstylist`, hairstylist)
            .then(() => dispatch(push('/hairstylists')))
            .catch(error => console.log(`Todo => Handle error`))
    }
}

/**
 * Add new hairstylist
 * 
 * @param {Object} hairstylist 
 * @returns dispatch action
 */
export const addHairstylist = hairstylist => {
    return dispatch => {
        axios.post(`/add/hairstylist`, hairstylist)
            .then(() => dispatch(push('/hairstylists')))
            .catch(error => console.log(`Todo => Handle error.`))
    }
}

/**
 * Delete a single hairstylist
 * 
 * @param {String} _id 
 * @returns dispatch action
 */
export const deleteHairstylist = _id => {
    return dispatch => {
        axios.post(`/delete/hairstylist`, { _id })
            .then(() => dispatch(delete_hairstylist(_id)))
            .catch(error => console.log(`Todo => Handle error.`))       
    }
}

/**
 * Reset hairstylist object
 * 
 * @returns dispatch action
 */
export const resetHairstylist = () => dispatch => dispatch(reset_hairstylist())

/**
 * Reset hairstylists array
 * 
 * @returns dispatch action
 */
export const resetHairstylists = () => dispatch => dispatch(reset_hairstylists())

/**
 * Handle input change
 * 
 * @returns dispatch action
 */
export const handleInputChange = e => dispatch => dispatch(handle_input_change(e.target.name, e.target.value))

/**
 * Upload image
 * 
 * @param {FormData} file 
 */
export const uploadImage = image => {
    const data = new FormData()
    data.append('image', image)
    return dispatch => {
        dispatch(set_uploading_image())
        axios.post(`/upload_image`, data)
            .then(response => {
                return dispatch(upload_image(response.data))
            })
            .catch(error => {
                console.log(error)
            })
    }
}

