import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    getHairstylists,
    deleteHairstylist,
    resetHairstylists
} from '../../modules/hairstylists'
import ContentHeader from '../../components/ContentHeader'
import { Table, TableHeader, TableBody } from '../../components/Table'
import styles from '../../styles/containers/hairstylists.module.scss'
import OptionsList from '../../components/OptionsList'
import { Link } from 'react-router-dom'
import { ImagesPath } from '../../constants'

class Hairstylists extends Component {
    
    constructor() {
        super()

        this.handleDeleteHairstylist = this.handleDeleteHairstylist.bind(this)
    }

    componentDidMount() {
        this.props.getHairstylists()
    }

    componentWillUnmount() {
        this.props.resetHairstylists()
    }

    handleDeleteHairstylist(e) {
        return this.props.deleteHairstylist(e.target.dataset.id)
    }
    
    render() {
        const { hairstylists = [], loading } = this.props
        return(
            <Fragment>
                <ContentHeader
                    title="Hairstylists"
                    description="View the list of all active hairstylists.">
                    <Link to="/add/hairstylist">Add new hairstylist</Link>
                </ContentHeader>
                <div className="main-content">
                    <Table>
                        <TableHeader headerItems={['Image', 'Name', 'Active', 'Instagram', 'Actions']} />
                        <TableBody>
                                {hairstylists.map(hairstylist =>
                                    <tr key={hairstylist._id}>
                                        <td className={styles.hairstylistImage} style={hairstylist.images ? { backgroundImage: `url(${ImagesPath}/${hairstylist.images.small})` } : {}}></td>
                                        <td>{hairstylist.name}</td>
                                        <td>{hairstylist.active ? 'Yes' : 'No'}</td>
                                        <td>
                                            <a href={`https://instagram.com/${hairstylist.instagram}`} target="_blank">
                                                {hairstylist.instagram}
                                            </a>
                                        </td>
                                        <td>
                                            <OptionsList>
                                                <Link to={`/edit/hairstylist/${hairstylist._id}`}>Edit hairstylist</Link>
                                                <button
                                                    data-id={hairstylist._id}
                                                    onClick={this.handleDeleteHairstylist}>
                                                    Delete hairstylist
                                                </button>
                                            </OptionsList>
                                        </td>
                                    </tr>    
                                )}
                        </TableBody>
                    </Table>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ hairstylists }) => ({
    hairstylists: hairstylists.hairstylists,
    loading: hairstylists.loading
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getHairstylists,
    deleteHairstylist,
    resetHairstylists
}, dispatch)

Hairstylists.propTypes = {
    hairstylists: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    getHairstylists: PropTypes.func.isRequired,
    deleteHairstylist: PropTypes.func.isRequired,
    resetHairstylists: PropTypes.func.isRequired
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Hairstylists)