import React, { useState } from 'react'
import ClickOutsideHandler from 'react-outside-click-handler'
import PropTypes from 'prop-types'
import styles from '../../src/styles/components/OptionsList.module.scss'

const OptionsList = props => {
    
    const [show, toggleShow] = useState(false)

    return(
        <div className={styles.optionsList}>
            <button 
                onClick={() => toggleShow(!show)} 
                className={styles.toggleButton}></button>
            {show &&
                <ClickOutsideHandler onOutsideClick={() => toggleShow(!show)}>
                    <div className={styles.optionsListOptions}>
                        {props.children}
                    </div>
                </ClickOutsideHandler>}
        </div>
    )
}

OptionsList.propTypes = {
    children: PropTypes.node.isRequired
}

export default OptionsList