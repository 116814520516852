import React, { Component } from 'react';
import { Input } from '../../components/Forms';
import styles from '../../styles/containers/login.module.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    doLogin,
    doLogout
} from '../../modules/auth';
import { push } from 'react-router-redux';

class Login extends Component {
    
    constructor() {
        super()
        this.state = {
            username: '',
            password: ''
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    componentWillMount() {
        if(localStorage.getItem('loggedIn')) {
            this.props.changePage('hairstylists');
        }
    }
    
    handleInputChange(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    handleFormSubmit(e) {
        e.preventDefault();
        const { username, password } = this.state;
        this.props.doLogin(username, password);
    }
    
    render() {
        const { incorrectPassword } = this.props;
        return(
            <div className={styles.loginContainer}>
                <h1>Log in to <b>Royal Brides</b></h1>
                <form onSubmit={this.handleFormSubmit}>
                    <Input
                        name="username"
                        placeholder="Username"
                        onChange={this.handleInputChange}
                        showLabel={false} />
                    <Input
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={this.handleInputChange}
                        showLabel={false} />
                    <button>Log In</button>
                </form>
                {incorrectPassword && <h1>Incorrect password, please try again.</h1>}
            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => ({
    incorrectPassword: auth.incorrectPassword
})

const mapDispatchToProps = dispatch => bindActionCreators({
    doLogin,
    doLogout,
    changePage: page => push(page)
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)