import { axios } from '../services'
import { push } from 'react-router-redux'
export const GET_CITIES         =   'cities/GET_CITIES'
export const GET_CITY           =   'cities/GET_CITY'
export const DELETE_CITY        =   'cities/DELETE_CITY'
export const UPLOAD_IMAGE       =   'cities/UPLOAD_IMAGE'

export const RESET_CITY             =   'cities/RESET_CITY'
export const RESET_CITIES           =   'cities/RESET_CITIES'

export const HANDLE_INPUT_CHANGE    =   'cities/HANDLE_INPUT_CHANGE'

export const SET_UPLOADING_IMAGE    =   'cities/SET_UPLOADING_IMAGE'

const initialState = {
    cities: [],
    city: {},
    uploadingImage: false,
    loading: true
}

export default ( state = initialState, action ) => {
    switch(action.type) {
        case GET_CITIES:
            return {
                ...state,
                cities: action.payload,
                loading: false
            }
        case GET_CITY:
            return {
                ...state,
                city: action.payload,
                loading: false
            }
        case DELETE_CITY:
            return {
                ...state,
                cities: state.cities.filter(city => city._id !== action.payload._id)
            }
        case UPLOAD_IMAGE:
            return {
                ...state,
                city: {
                    ...state.city,
                    images: action.payload
                },
                uploadingImage: false
            }
        case RESET_CITY:
            return {
                ...state,
                city: {},
                loading: true
            }
        case RESET_CITIES:
            return {
                ...state,
                cities: [],
                loading: true
            }
        case HANDLE_INPUT_CHANGE:
            return {
                ...state,
                city: {
                    ...state.city,
                    [action.payload.name]: action.payload.value
                }
            }
        case SET_UPLOADING_IMAGE:
            return {
                ...state,
                uploadingImage: true
            }
        default:
            return state
    }
}

/**
 * Calls GET_CITIES Action
 * 
 * @param {Array} cities 
 */
const get_cities = cities => ({
    type: GET_CITIES,
    payload: cities
})

/**
 * Calls GET_CITY Action
 * 
 * @param {Object} city 
 */
const get_city = city => ({
    type: GET_CITY,
    payload: city
})

/**
 * Deletes the city and returns the new array
 * 
 * @param {String} _id 
 */
const delete_city = _id => ({
    type: DELETE_CITY,
    payload: { _id }
})

/**
 * Replaces the uploaded_image object with given object
 * 
 * @param {Object} images
 */
const upload_image = images => ({
    type: UPLOAD_IMAGE,
    payload: images
})

/**
 * Resets city object in state
 * Usually called when unmounting the component
 */
const reset_city = () => ({
    type: RESET_CITY
})

/**
 * Resets the cities array in state
 * Usually called when unmounting the component
 */
const reset_cities = () => ({
    type: RESET_CITIES
})

/**
 * On input change
 */
const on_input_change = (name, value) => ({
    type: HANDLE_INPUT_CHANGE,
    payload: { name, value }
})

/**
 * Set uploading image
 */
const set_uploading_image = () => ({
    type: SET_UPLOADING_IMAGE
})

/**
 * Gets all cities from the backend
 * 
 * @returns dispatch action with received array
 */
export const getCities = () => {
    return dispatch => {
        axios.get(`/cities`)
            .then(response => dispatch(get_cities(response.data)))
            .catch(error => console.log(`Todo => Handle error.`))
    }
}

/**
 * Gets a city based on ide
 * 
 * @param {String} id
 * @returns dispatch action with received object
 */
export const getCity = id => {
    return dispatch => {
        axios.get(`/city/${id}`)
            .then(response => dispatch(get_city(response.data)))
            .catch(error => console.log(`Todo => Handle error.`))
    }
}

/**
 * Adds a new city
 * 
 * @param {Object} city 
 * @returns redirection to the main cities page
 */
export const addCity = city => {
    return dispatch => {
        axios.post('/add/city', city)
            .then(() => dispatch(push('/cities')))
            .catch(error => console.log(`Todo => Handle error`))
    }
}

/**
 * Deletes the city using their _id
 * 
 * @param {String} _id 
 * @returns dispatch action to delete the city (using filter) from state
 */
export const deleteCity = _id => {
    return dispatch => {
        axios.post(`/delete/city`, { _id })
            .then(() => dispatch(delete_city(_id)))
            .catch(error => console.log(`Handle error`))
    }
}

/**
 * Upload image
 * 
 * @param {FormData} file 
 */
export const uploadImage = image => {
    const data = new FormData()
    data.append('image', image)
    return dispatch => {
        dispatch(set_uploading_image())
        axios.post(`/upload_image`, data)
            .then(response => {
                return dispatch(upload_image(response.data))
            })
            .catch(error => {
                console.log(error)
            })
    }
}

/**
 * Updates a city
 * 
 * @param {Object} city 
 * @returns redirection to the main cities page
 */
export const updateCity = city => {
    return dispatch => {
        axios.post(`/update/city`, city)
            .then(() => dispatch(push('/cities')))
            .catch(error => console.log(error))
    }
}

/**
 * Reset city
 * 
 * @returns dispatch action to reset city object
 */
export const resetCity = () => dispatch => dispatch(reset_city())

/**
 * Reset cities
 * 
 * @returns dispatch action to reset cities array
 */
export const resetCities = () => dispatch => dispatch(reset_cities())

/**
 * Handles input change
 * 
 * @returns dispatch action to update the object using on input change
 */

export const onInputChange = e => dispatch => dispatch(on_input_change(e.target.name, e.target.value))