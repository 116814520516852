import React from 'react'
import PropTypes from 'prop-types'
import styles from '../../src/styles/components/ContentHeader.module.scss'

const ContentHeader = ({ title, description, children }) => (
    <div className={styles.contentHeader}>
        <div className={styles.contentHeaderInner}>
            <div className={styles.contentHeaderCol}>
                <h1>{title}</h1>
                <h2>{description}</h2>
            </div>
            <div className={styles.contentHeaderCol}>
                {children}
            </div>
        </div>
    </div>
)

ContentHeader.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    children: PropTypes.node
}

export default ContentHeader