import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    getCities,
    deleteCity,
    resetCities
} from '../../modules/cities'
import ContentHeader from '../../components/ContentHeader'
import { Table, TableHeader, TableBody } from '../../components/Table'
import OptionsList from '../../components/OptionsList'
import { Link } from 'react-router-dom'

class Cities extends Component {
    
    constructor() {
        super()

        this.handleDeleteCity = this.handleDeleteCity.bind(this)
    }

    componentDidMount() {
        this.props.getCities()
    }

    componentWillUnmount() {
        this.props.resetCities()
    }

    handleDeleteCity(e) {
        return this.props.deleteCity(e.target.dataset.id)
    }

    render() {
        const { loading, cities = [] } = this.props
        // Use loading bool to show loading animation on the table
        return(
            <Fragment>
                
                <ContentHeader
                    title="Cities"
                    description="View the list of all active cities.">
                    <Link to="/add/city">Add new city</Link>
                </ContentHeader>

                <div className="main-content">
                    <Table>
                        <TableHeader headerItems={['Name', 'Actions']} />
                        <TableBody>
                            {cities.map(city =>
                                <tr key={city._id}>
                                    <td>{city.name}</td>
                                    <td>
                                        <OptionsList>
                                            <Link to={`/edit/city/${city._id}`}>Edit city</Link>
                                            <button 
                                                data-id={city._id}
                                                onClick={this.handleDeleteCity}>Delete city</button>
                                        </OptionsList>
                                    </td>
                                </tr>    
                            )}
                        </TableBody>
                    </Table>
                </div>
                
            </Fragment>
        )
    }
}

const mapStateToProps = ({ cities }) => ({
    cities: cities.cities,
    loading: cities.loading
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getCities,
    deleteCity,
    resetCities
}, dispatch)

Cities.propTypes = {
    cities: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    getCities: PropTypes.func.isRequired,
    deleteCity: PropTypes.func.isRequired,
    resetCities: PropTypes.func.isRequired
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Cities)