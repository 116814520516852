import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import Header from '../../components/Header'
import Home from '../home'
import Cities from '../cities'
import AddCity from '../addCity'
import EditCity from '../editCity'
import Hairstylists from '../hairstylists'
import AddHairstylist from '../addHairstylist'
import EditHairstylist from '../editHairstylist'
import Login from '../login'

class App extends React.Component {
  
  constructor() {
    super()
    this.state = {
      hideHeader: true
    }

    this.isLoginPage = this.isLoginPage.bind(this)
  }

  componentDidMount() {
    this.isLoginPage(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.isLoginPage(nextProps)
  }

  isLoginPage(props) {
    if(props.location.pathname.includes('login')) {
      return this.setState({ hideHeader: true });
    }
    return this.setState({ hideHeader: false });
  }

  render() {
    const { hideHeader } = this.state
    return(
      <div>
        {!hideHeader ? <Header /> : null}
        <main className={hideHeader ? 'full-width' : ''}>
          <Route exact path="/" component={Home} />
          <Route exact path="/cities" component={Cities} />
          <Route exact path="/add/city" component={AddCity} />
          <Route exact path="/edit/city/:id" component={EditCity} />
    
          <Route exact path="/hairstylists" component={Hairstylists} />
          <Route exact path="/add/hairstylist" component={AddHairstylist} />
          <Route exact path="/edit/hairstylist/:id" component={EditHairstylist} />
    
          <Route exact path="/login" component={Login} />
        </main>
      </div>
    )
  }

}

export default withRouter(App);