import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/components/Header.module.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    doLogout
} from '../modules/auth';

const Header = props => (
    <header className={styles.header}>
        <div className={styles.headerTop}>
            <h1>Royal Brides</h1>
            <h2>Administration Panel</h2>
        </div>
        <div className={styles.headerInner}>
            <Links />
        </div>
        <div className={styles.headerBottom}>
            <button onClick={props.doLogout}>Log out, admin.</button>
        </div>
    </header>
)

export const Links = () => (
    <ul className={styles.linksList}>
        <li><Link to="/cities">Cities</Link></li>
        <li><Link to="/hairstylists">Hairstylists</Link></li>
    </ul>
)

const mapDispatchToProps = dispatch => bindActionCreators({
    doLogout
}, dispatch);

export default connect(
    null,
    mapDispatchToProps
)(Header)