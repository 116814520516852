import React from 'react'
import PropTypes from 'prop-types'
import styles from '../styles/components/Table.module.scss'

export const Table = ({ children }) => (
    <table className={styles.tableData}>
        {children}
    </table>
)

Table.propTypes = {
    children: PropTypes.node.isRequired
}

export const TableHeader = ({ headerItems = [] }) => (
    <tr className={styles.tableHeader}>
        {headerItems.map((headerItem, index) => 
            <th key={index}>{headerItem}</th>    
        )}
    </tr>
)

TableHeader.propTypes = {
    headerItems: PropTypes.array.isRequired
}

export const TableBody = ({ children }) => children

TableBody.propTypes = {
    children: PropTypes.node.isRequired
}